<template>
  <div>
    <!-- banner -->
    <carouselBanner paddingTop="13.5%" :bannerType="9" />

    <div class="content_area">
      <!-- 咨询 -->
      <div v-if="type == 1" v-loading="loading">
        <div class="active_name"><span>跨境电商资讯</span></div>
        <div class="item">
          <div class="consult" v-for="(item, i) in consultList" :key="i">
            <Information
              class="item-1"
              :item_data="item"
              @viewDetail="onViewDetail"
            ></Information>
          </div>
        </div>
        <noDataImg v-if="!loading && consultList.length === 0"></noDataImg>
      </div>
      <!-- 视频 -->
      <div v-else v-loading="videoloading">
        <div class="active_name"><span>跨境电商视频</span></div>
        <div class="item">
          <div v-for="(item, i) in videoList" :key="i" class="video-item">
            <CrossVideo class="item-1" :item_data="item"></CrossVideo>
          </div>
        </div>
        <noDataImg v-if="!videoloading && videoList.length === 0"></noDataImg>
      </div>
      <div class="footer-content">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="type == 1 ? 8 : 12"
          @current-change="type == 1 ? getList() : getVideoList()"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Information from "~xif/components/crossBorder/information";
import CrossVideo from "~xif/components/crossBorder/eCommerceVideo";
import carouselBanner from "@/baseComponents/carouselBanner";

export default {
  data() {
    return {
      loading: false,
      videoloading: false,
      currentPage: 1,
      total: 0,
      type: "",
      consultList: [],
      videoList: [],
    };
  },
  components: {
    Information,
    CrossVideo,
    carouselBanner,
  },
  created() {
    this.type = this._decode(this.$route.query.parameter).type;
  },
  mounted() {
    if (this.type === 1) {
      this.getList();
    } else {
      this.getVideoList();
    }
  },
  beforeDestroy() {
    this.currentPage = 1;
    this.total = 0;
    console.log("currentPage,total 已销毁 ", this.type);
  },
  methods: {
    // 咨询列表
    async getList() {
      this.loading = true;
      try {
        let params = {
          content_type: 17,
          source: this.PJSource,
          nopage: 0,
          limit: 8,
          start: this.currentPage - 1,
          is_show: 1,
          language: this.LOCALE === "en" ? 1 : 2,
        };
        let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
        );
        if (res.success) {
          this.consultList = res.data;
          this.total = res.total;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    // 视频列表
    async getVideoList() {
      this.videoloading = true;
      try {
        let params = {
          source: this.PJSource,
          start: this.currentPage - 1,
          limit: 12,
          nopage: 0,
          video_category: 5
        };
        let res = await this.$store.dispatch(
          "API_company/association_video_List",
          params
        );
        if (res.success) {
          this.videoList = res.data;
          this.total = res.total;
          this.videoloading = false;
        }
      } catch (error) {
        this.videoloading = false;
      }
    },
    // 查看详情
    onViewDetail(value) {
      console.log(value);
      this.$router.push({
        path: "/detail",
        query: { id: value.id },
      });
    },
  },
};
</script>

<style scoped lang="less">
.footer-content {
  margin: 20px 0;
}
.el-pagination {
  position: relative;
  text-align: right;
  padding-right: 10px;
}
.item {
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
}
.video-item,
.consult {
  margin-top: 20px;
  margin-right: 24px;
}
.consult:nth-child(2n) {
  margin-right: 0;
}
.video-item:nth-child(4n) {
  margin-right: 0;
}
.active_name {
  text-align: center;
  span {
    font-size: 21px;
    font-weight: 800;
    color: #1f292e;
    line-height: 29px;
  }
}
</style>